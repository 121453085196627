import { Box, CircularProgress, Dialog, Slide } from "@mui/material";
import Button from "@mui/material/Button";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useState } from "react";
import { IObjectError } from "../../../interfaces";
import { useSyncFeedback } from "./hooks";

export type ModalErrorsProps = {
  setShowModal: (value: boolean) => void;
  errorLog: IObjectError[];
  showModal: boolean;
};

export default function SyncFeedback({ setShowModal, errorLog, showModal }: ModalErrorsProps) {
  const { tools } = useSyncFeedback();
  const [helpdeskTicketStatus, setHelpdeskTicketStatus] = useState<"ready" | "loading" | "sent" | "error">("ready");

  return (
    <Dialog
      open={showModal}
      onClose={() => setShowModal(false)}
      TransitionComponent={Transition}
      fullWidth={true}
      maxWidth="lg"
    >
      <Box>
        <Box sx={stylesModal}>
          {!!errorLog.length ? (
            errorLog.map((item: any, index: any) => {
              return (
                <Box key={index} title={index} sx={stylesErrors}>
                  <p>
                    <b>SQL: </b>
                    <span>{item.sql || "SQL não gerado"}</span>
                  </p>
                  <p>
                    <b>Erro: </b>
                    <span>{item.error}</span>
                  </p>
                </Box>
              );
            })
          ) : (
            <Box sx={stylesError}>
              <p>{JSON.stringify(errorLog)}</p>
            </Box>
          )}
        </Box>
        <Box sx={stylesButtons}>
          <Button
            variant="contained"
            color="success"
            disabled={helpdeskTicketStatus === "loading" || helpdeskTicketStatus === "sent"}
            sx={{ minWidth: "10%" }}
            onClick={() => {
              setHelpdeskTicketStatus("loading");
              tools
                .callHelpDesk(errorLog)
                .then(() => setHelpdeskTicketStatus("sent"))
                .catch(() => setHelpdeskTicketStatus("error"));
            }}
          >
            {helpdeskTicketStatus === "loading" && <CircularProgress size={10} />}
            &nbsp; &nbsp; Abrir chamado no Help Desk
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ minWidth: "10%" }}
            onClick={() => tools.copyError(errorLog)}
          >
            Copiar erro para a área de transferência
          </Button>
          <Button variant="contained" color="warning" sx={{ minWidth: "10%" }} onClick={() => setShowModal(false)}>
            Ok
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const stylesModal = {
  padding: "6px",
};

const stylesErrors = {
  marginBottom: "6px",
  padding: "6px",
  border: "2px solid #000",
};

const stylesError = {
  marginBottom: "6px",
  padding: "6px",
  height: "100px",
};

const stylesButtons = {
  padding: "12px 0",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
};
