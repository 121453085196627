import { ILog, IlogDelete, IlogInsert, IlogUpdate } from "../interfaces";

/**
 * Registra uma atualização no log de mudanças.
 *
 * @param {IlogUpdate} logEntry - O registro de atualização a ser adicionado ao log.
 * @returns {void}
 *
 * @example
 * const updateEntry = {
 *   nome_tabela: "tabela",
 *   id_origem: "123",
 *   nome_campo: "campo",
 *   tipo_campo: "text",
 *   valor_campo: "novo_valor"
 * };
 *
 * logUpdate(updateEntry);
 */
export function logUpdate(logEntry: IlogUpdate): void {
  if (!localStorage.getItem("log")) {
    localStorage.setItem("log", JSON.stringify({ inserts: [], updates: [], deletes: [] }));
  }

  if (!localStorage.getItem("commit_no")) {
    localStorage.setItem("commit_no", "1");
  }

  const log = JSON.parse(localStorage.getItem("log")!);
  logEntry["numero_commit"] = Number(JSON.parse(localStorage.getItem("commit_no")!));
  logEntry["timestamp"] = new Date().toISOString();

  function verifyIfEntryExists(logEntry: any) {
    return log.updates.findIndex((e: any) => {
      return (
        e.nome_tabela === logEntry.nome_tabela &&
        e.id_origem === logEntry.id_origem &&
        e.nome_campo === logEntry.nome_campo
      );
    });
  }

  function updateEntry(index: number, logEntry: any) {
    logEntry["id"] = index + 1;
    log.updates[index] = logEntry;
    return null;
  }

  function insertEntry(logEntry: any) {
    logEntry["id"] = log.updates.length;
    log.updates.push(logEntry);
    return null;
  }

  if (verifyIfEntryExists(logEntry) !== -1) {
    updateEntry(verifyIfEntryExists(logEntry), logEntry);
  } else {
    insertEntry(logEntry);
  }

  localStorage.setItem("log", JSON.stringify(log));
}

/**
 * Registra uma inserção no log de mudanças.
 *
 * @param {IlogInsert} logEntry - O registro de inserção a ser adicionado ao log.
 * @returns {void}
 *
 * @example
 * const insertEntry = {
 *   nome_tabela: "tabela",
 *   fid: "456"
 * };
 *
 * logInsert(insertEntry);
 */
export function logInsert(logEntry: IlogInsert): void {
  if (!localStorage.getItem("log")) {
    localStorage.setItem("log", JSON.stringify({ inserts: [], updates: [], deletes: [] }));
  }

  const log = JSON.parse(localStorage.getItem("log")!);

  function verifyIfEntryExists(logEntry: any) {
    return log.inserts.findIndex((e: any) => {
      return e.nome_tabela === logEntry.nome_tabela && e.fid === logEntry.fid;
    });
  }

  function entryInsert(index: number, logEntry: any) {
    logEntry["id"] = index + 1;
    logEntry["timestamp"] = new Date().toISOString();
    log.inserts[index] = logEntry;
    return null;
  }

  function insertEntry(logEntry: any) {
    logEntry["id"] = log.inserts.length;
    logEntry["timestamp"] = new Date().toISOString();
    log.inserts.push(logEntry);
    return null;
  }

  if (verifyIfEntryExists(logEntry) !== -1) {
    entryInsert(verifyIfEntryExists(logEntry), logEntry);
  } else {
    insertEntry(logEntry);
  }
  localStorage.setItem("log", JSON.stringify(log));
}

/**
 * Registra uma exclusão no log de mudanças.
 *
 * @param {IlogDelete} logEntry - O registro de exclusão a ser adicionado ao log.
 * @returns {void}
 *
 * @example
 * const deleteEntry = {
 *   nome_tabela: "tabela",
 *   id_origem: "789"
 * };
 *
 * logDelete(deleteEntry);
 */
export function logDelete(logEntry: IlogDelete): void {
  let id_origem = logEntry.id_origem;
  let nome_tabela = logEntry.nome_tabela;

  if (!localStorage.getItem("log")) {
    localStorage.setItem("log", JSON.stringify({ inserts: [], updates: [], deletes: [] }));
  }

  const log: ILog = JSON.parse(localStorage.getItem("log")!);

  logEntry.id = log.deletes.length + 1;
  logEntry["timestamp"] = new Date().toISOString();

  if (log.inserts.find((e) => e.fid === id_origem && e.nome_tabela === nome_tabela)) {
    removeIdFromLog(id_origem);
    return;
  } else {
    log.deletes.push(logEntry);
    localStorage.setItem("log", JSON.stringify(log));
  }
}

function removeIdFromLog(id: string | number) {
  if (!localStorage.getItem("log")) {
    localStorage.setItem("log", JSON.stringify({ inserts: [], updates: [], deletes: [] }));
  }

  const log: ILog = JSON.parse(localStorage.getItem("log")!);

  log.inserts = log.inserts.filter((e) => e.fid != id);
  log.updates = log.updates.filter((e) => e.id_origem != id);
  log.deletes = log.deletes.filter((e) => e.id_origem != id);

  log.deletes.forEach((entry) => {
    entry["timestamp"] = new Date().toISOString();
  });

  localStorage.setItem("log", JSON.stringify(log));
}
