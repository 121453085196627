import axios from "axios";
import { environments } from "../environments";
import { getTokens } from "../services/data";

export const apiPlataforma = axios.create({
  baseURL: environments.urlApiPlataforma,
});

apiPlataforma.interceptors.request.use(
  (config) => {
    config.headers["X-TN-TOKEN"] = `${getTokens()["x-tn-token"]}`;
    config.headers["X-AUTH-TOKEN"] = `${getTokens()["x-auth-token"]}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
