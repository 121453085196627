import moment from "moment";
import toast from "react-hot-toast";
import pj from "../../../../package.json";
import { environments } from "../../../environments";
import { IObjectError } from "../../../interfaces";
import { sendFeedbackSincronizacao } from "../../../services/api";
import { getMunicipio } from "../../../services/data";

export function useSyncFeedback() {
  const ambiente = environments.ambiente;
  const urlApiEspecifica = environments.urlApiCadastro;
  const urlApiGateway = environments.urlApiGateway;
  const urlApiGenerica = environments.urlApiPlataforma;
  const versao = pj.version;
  const userAuthToken = localStorage.xAuthToken;
  const tenantToken = localStorage.xTnToken;
  const nomeMunicipio = getMunicipio();
  const logData = localStorage.getItem("log") || "{}";
  const isDevMode = localStorage.devMode === "true";
  const devModeText = isDevMode ? " - MODO DESENVOLVEDOR" : "";

  function generateErrorText(errorLog: IObjectError[]) {
    const errorText = `
    # RELATÓRIO DE ERROS DA CARACTERIZAÇÃO${devModeText}
    
    O seguinte conteúdo foi enviado para sincronização: ${logData}
    
    O seguinte erro foi encontrado: ${JSON.stringify(errorLog)}
    
    Detalhes:
    Versão da aplicação: ${versao} - ${ambiente}
    URL da API Específica: ${urlApiEspecifica}
    URL da API Gateway: ${urlApiGateway}
    URL da API Genérica: ${urlApiGenerica}
    Município: ${nomeMunicipio}
    Token do Usuário: ${userAuthToken}
    Token do Tenant: ${tenantToken}
    Data e hora do erro: ${moment().format("DD MMMM YYYY, h:mm:ss a")}`;

    return errorText;
  }

  async function callHelpDesk(errorLog: IObjectError[]) {
    try {
      const data = await sendFeedbackSincronizacao(errorLog);
      toast.success(
        "Um ticket foi criado com sucesso em nosso HelpDesk e tentaremos recuperar seus dados, caso necessário, entraremos em contato no endereço cadastrado como usuário do Geo360",
      );
      return data;
    } catch (error) {
      console.error(error);
      toast.error(
        "Houve um erro no envio do ticket ao nosso HelpDesk, copie o relatório de erros e entre em contato com o suporte do Geo360",
      );
    }
  }

  function copyError(error: IObjectError[]) {
    navigator.clipboard.writeText(generateErrorText(error));
    toast.success("O relatório de erros foi copiado para sua área de transferência");
  }

  const tools = {
    callHelpDesk,
    copyError,
  };

  return {
    tools,
  };
}
