import styled from "styled-components";

interface ElementsThatInteractWithDrawerProps {
  drawer: boolean;
}

export const LeftBottomContainer = styled.div<ElementsThatInteractWithDrawerProps>`
  position: fixed;
  bottom: 15px;
  right: ${(props) => (props.drawer ? "calc(30vw + 25px)" : "25px")};
`;
