import axios from "axios";
import { environments } from "../environments";
import { getTokens } from "../services/data";

export const apiGateway = axios.create({
  baseURL: environments.urlApiGateway,
});

apiGateway.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${getTokens()["x-auth-token"]}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
