import { useState } from "react";
import { isCurrentColorModeDark } from "../../../../providers/Theme";

export function useTextArea() {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const styleTextArea = {
    width: "100%",
    height: "100px",
    maxWidth: "90vw",
    maxHeight: "75vh",
    minHeight: "100px",
    minWidth: "300px",
    color: isCurrentColorModeDark() ? "#C7D0DD" : "#1C2025",
    background: isCurrentColorModeDark() ? "#1C2025" : "#fff",
  };

  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "12px",
    padding: "24px",
  };

  return {
    openDialog,
    setOpenDialog,
    styleTextArea,
    style,
  };
}
