import { Box, Drawer, Tab, Tabs } from "@mui/material";
import { useContext, useState } from "react";
import { DashboardContext } from "../../../providers/Dashboard";
import { isCurrentColorModeDark } from "../../../providers/Theme";
import Viewer360 from "../360";
import Map from "../Map";
import Media from "../Media";
import { Container } from "./styles";

type DrawerTabs = "map" | "360" | "media";

export default function SideDrawer() {
  const { metadata, setMetadata } = useContext(DashboardContext);
  const [drawerTab, setDrawerTab] = useState<DrawerTabs>("map");

  function handleChange(event: React.SyntheticEvent, newValue: DrawerTabs) {
    setDrawerTab(newValue);
  }

  const tabSx = {
    "&.Mui-selected": isCurrentColorModeDark()
      ? {
          color: "primary.contrastText",
        }
      : {},
    "&:hover": isCurrentColorModeDark()
      ? {
          color: "primary.contrastText",
          opacity: 1,
        }
      : {},
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      hideBackdrop
      open={metadata.drawer}
      sx={{
        width: "30vw",
        minWidth: "30vw",
      }}
    >
      <Container>
        <Box>
          <Tabs
            variant="fullWidth"
            value={drawerTab}
            onChange={handleChange}
            sx={{
              height: "50px",
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#009c66",
              },
            }}
          >
            <Tab sx={tabSx} label={"Mapa"} value={"map"} />
            <Tab sx={tabSx} label={"360"} value={"360"} />
            <Tab sx={tabSx} label={"Mídias"} value={"media"} />
          </Tabs>

          {drawerTab === "map" && <Map />}
          {drawerTab === "360" && <Viewer360 />}
          {drawerTab === "media" && <Media />}
        </Box>
      </Container>
    </Drawer>
  );
}
