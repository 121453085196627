import { Toaster } from "react-hot-toast";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import { DashboardProvider } from "./providers/Dashboard";
import Theme from "./providers/Theme";
import HealthCheck from "./pages/HealthCheck";

function App() {
  return (
    <Theme>
      <Toaster position="top-right" />
      <BrowserRouter>
        <Switch>
          <Route exact path="/health">
            <HealthCheck />
          </Route>
          <Route exact path="/">
            <Login />
          </Route>
          <Route path="/dashboard/:id">
            <DashboardProvider>
              <Dashboard />
            </DashboardProvider>
          </Route>
          <Route path="*">
            <Redirect to={"/"} />
          </Route>
        </Switch>
      </BrowserRouter>
    </Theme>
  );
}

export default App;
