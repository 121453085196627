import { createContext } from "react";
import { IDebounceContext } from "../../interfaces";

/**
 * Contexto de Debounce para gerenciamento de validações com atraso.
 * @type {React.Context<IDebounceContext>}
 */
export const DebounceContext = createContext<IDebounceContext>({
  handleValidate: () => {},
});

/**
 * Provedor do Debounce para gerenciamento de validações com atraso.
 * @param {Object} props - Propriedades do componente.
 * @param {React.ReactNode} props.children - Componentes filhos a serem envolvidos pelo provedor do Debounce.
 * @returns {JSX.Element} Retorna o provedor do Debounce que gerencia as validações com atraso.
 */
export function DebounceProvider({ children }: any) {
  /**
   * Função de debounce para aplicar um atraso antes de executar uma validação.
   * @template T - Tipos dos argumentos da função.
   * @param {(...args: T) => void} func - Função a ser executada após o atraso.
   * @param {number} delay - Atraso em milissegundos.
   * @returns {(...args: T) => void} Retorna uma função debounced.
   */
  const debounce = <T extends any[]>(func: (...args: T) => void, delay: number): ((...args: T) => void) => {
    let timeoutId: NodeJS.Timeout;

    return (...args: T) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  /**
   * Função para validar um campo de forma única após o atraso.
   * @param {string} value - Valor a ser validado.
   * @param {any} validate - Função de validação a ser executada.
   */
  function validateUniqueField(value: string, validate: any) {
    validate(value);
  }

  /**
   * Função de validação debounced para aplicar um atraso antes de executar a validação do campo.
   * Executa a função de validação após um atraso de 500ms.
   */
  const handleValidate = debounce(validateUniqueField, 500);

  return (
    <DebounceContext.Provider
      value={{
        handleValidate,
      }}
    >
      {children}
    </DebounceContext.Provider>
  );
}
