import { OpenInFull } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slide,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import "moment/locale/pt-br";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { CamposSelect, ProjetosCamadasFormulariosCampo, Tabelas } from "../../../interfaces";
import { DashboardContext } from "../../../providers/Dashboard";
import { DebounceContext } from "../../../providers/Debounce";
import {
  getBairroOrLogradouro,
  getBairroOrLogradouroById,
  getBairroOrLogradouroByName,
  verifyIfInscricaoOrNumeroCadastroAlreadyExists,
} from "../../../services/api";
import { getValue, patch } from "../../../services/db";
import { logUpdate } from "../../../services/log";
import { converteTipoCampo } from "../../../services/utils";
import { useTextArea } from "./hooks";
import "./styles.css";

export default function Input(props: { tabela: Tabelas; campo: ProjetosCamadasFormulariosCampo }) {
  const { metadata, errors, setErrors, reloadFields } = useContext(DashboardContext);
  const { handleValidate } = useContext(DebounceContext);
  const { openDialog, setOpenDialog, styleTextArea, style } = useTextArea();

  const limitRadioGroup = 10;

  const theme = useTheme();

  const [value, setValue] = useState<any>("");
  const [isLoad, setLoad] = useState<boolean>(false);

  const [search, setSearch] = useState<string | undefined>(undefined);
  const entityType = props.campo.tblNomeColuna === "id_bairro" ? "bairro" : "logradouro";
  const searchableColumns = ["id_bairro", "id_logradouro"];

  const inputId = `${metadata[`id_${props.tabela}`]!}@${props.tabela}@${props.campo.tblNomeColuna}`;
  // Campos que nao serão carregados no input
  const excludedFields = ["geom"];

  async function fetchBairrosOrLogradouros() {
    setLoad(false);
    let data = [];

    if (value) {
      data = await getBairroOrLogradouro(entityType);

      const current = await getBairroOrLogradouroById(entityType, value);

      data.push({
        nome: current[0].nome,
        id: current[0].id,
      });

      const dataOptions = data.map((item: any) => {
        return {
          "@id": item.id,
          id: item.id,
          nome: item.nome,
          ativo: item.ativo || true,
          fator: item.fator || "",
          projetoCamadaFormularioCampoId: item.projetoCamadaFormularioCampoId || "",
          tblNomeColuna: item.tblNomeColuna || "",
          tblValorDb: item.id,
          projetoCamadaFormularioCampo: item.projetoCamadaFormularioCampo || "",
        };
      });

      const allOptions = [...dataOptions, ...props.campo.camposSelect];

      props.campo.camposSelect = allOptions;

      props.campo.tipo = 8;

      setLoad(true);
    }
  }

  async function fetchBairrosOrLogradourosByName() {
    if (!search) return;
    const data = await getBairroOrLogradouroByName(entityType, search);
    const dataOptions = data.map((item: any) => {
      return {
        "@id": item.id,
        id: item.id,
        nome: item.nome,
        ativo: item.ativo || true,
        fator: item.fator || "",
        tblValorDb: item.id,
        projetoCamadaFormularioCampo: item.projetoCamadaFormularioCampo || "",
      } as CamposSelect;
    });

    props.campo.camposSelect = dataOptions;

    props.campo.tipo = 8;
  }

  async function validate(value: any) {
    // props.campo.obrigatorio && console.log("Validando", inputId, value, "Obrigatório?", props.campo.obrigatorio);
    if (!isLoad) return;

    if (
      (props.campo.tblNomeColuna === "inscricao_cartografica" || props.campo.tblNomeColuna === "numero_cadastro") &&
      value !== ""
    ) {
      const valorNaoUnico = await verifyIfInscricaoOrNumeroCadastroAlreadyExists(
        props.tabela,
        value,
        props.campo.tblNomeColuna,
        metadata[`id_${props.tabela}`]!,
      );

      if (valorNaoUnico) {
        let storageErrors = JSON.parse(localStorage.errors);
        storageErrors = {
          ...storageErrors,
          [inputId]: {
            tabela: props.tabela,
            coluna: props.campo.tblNomeColuna,
            id: metadata[`id_${props.tabela}`]!,
            message: `O valor ${value} já está sendo utilizado`,
          },
        };

        setErrors(storageErrors);
        localStorage.errors = JSON.stringify(storageErrors);
      } else {
        let storageErrors = JSON.parse(localStorage.errors);
        delete storageErrors?.[inputId];
        localStorage.setItem("errors", JSON.stringify(storageErrors));
        setErrors(storageErrors);
      }
    } else if ((value === "" || !value || value === "-1") && props.campo.obrigatorio) {
      let storageErrors = JSON.parse(localStorage.errors);
      storageErrors = {
        ...storageErrors,
        [inputId]: {
          tabela: props.tabela,
          coluna: props.campo.tblNomeColuna,
          id: metadata[`id_${props.tabela}`]!,
          message: "Campo obrigatório",
        },
      };
      localStorage.errors = JSON.stringify(storageErrors);
      setErrors(storageErrors);
    } else {
      let storageErrors = JSON.parse(localStorage.errors);
      delete storageErrors?.[inputId];
      localStorage.setItem("errors", JSON.stringify(storageErrors));
      setErrors(storageErrors);
    }
  }

  function normalizeWord(text: string) {
    return text
      .toLowerCase()
      .trim()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  const inputProps = {
    fullWidth: true,
    label: props.campo.nome,
    disabled: props.campo.somenteVisualizacao || !metadata[`id_${props.tabela}`] ,
    value: value,
    required: props.campo.obrigatorio,
    error: !!errors?.[inputId],
    helperText: errors?.[inputId]?.message,
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!metadata[`id_${props.tabela}`]) return; // casos onde o id ainda não foi definido ou a filtragem de tabs nao retornou nada

      setValue(e.target.value);
      patch(metadata[`id_${props.tabela}`]!, props.tabela, props.campo.tblNomeColuna, e.target.value);
      logUpdate({
        id_origem: metadata[`id_${props.tabela}`]!,
        nome_tabela: props.tabela,
        nome_campo: props.campo.tblNomeColuna,
        valor_campo: e.target.value || null,
        tipo_campo: converteTipoCampo(props.campo.tipo),
      });

      if (props.campo.tblNomeColuna !== "inscricao_cartografica" && props.campo.tblNomeColuna !== "numero_cadastro") {
        validate(e.target.value);
      }
    },
    InputLabelProps: {
      sx: {
        "&.Mui-focused": {
          color: theme.palette.secondary.main,
        },
      },
    },
  };

  const inputDateProps = {
    value: moment(value),
    onChange: (e: any) => {
      if (!metadata[`id_${props.tabela}`]) return;
      setValue(e._d);
      patch(metadata[`id_${props.tabela}`]!, props.tabela, props.campo.tblNomeColuna, e._d);
      logUpdate({
        id_origem: metadata[`id_${props.tabela}`]!,
        nome_tabela: props.tabela,
        nome_campo: props.campo.tblNomeColuna,
        valor_campo: e._d,
        tipo_campo: converteTipoCampo(props.campo.tipo),
      });
      validate(e.target);
    },
  };

  useEffect(() => {
    if (!metadata[`id_${props.tabela}`]) return; // casos onde o id ainda não foi definido ou a filtragem de tabs nao retornou nada

    if (excludedFields.includes(props.campo.tblNomeColuna)) {
      return;
    }

    if (value === "" && !isLoad) {
      let retrievedValue = getValue(metadata[`id_${props.tabela}`]!, props.tabela, props.campo.tblNomeColuna);

      if (searchableColumns.includes(props.campo.tblNomeColuna)) {
        fetchBairrosOrLogradouros();
      }

      if (props.campo.tipo === 5 && retrievedValue === null) {
        retrievedValue = "-1";
        validate(retrievedValue);
      }

      retrievedValue && setValue(retrievedValue);
      validate(retrievedValue);

      setLoad(true);
    } else {
      let retrievedValue = getValue(metadata[`id_${props.tabela}`]!, props.tabela, props.campo.tblNomeColuna);
      retrievedValue && setValue(retrievedValue);
      validate(retrievedValue);
    }
  }, [reloadFields]);

  useEffect(() => {
    if (searchableColumns.includes(props.campo.tblNomeColuna)) {
      fetchBairrosOrLogradourosByName();
    }
  }, [search]);

  useEffect(() => {
    if (searchableColumns.includes(props.campo.tblNomeColuna)) {
      fetchBairrosOrLogradouros();
    }
  }, [props.campo]);

  useEffect(() => {
    validate(value);
  }, [value]);

  return (
    <>
      {!excludedFields.includes(props.campo.tblNomeColuna) && (
        <Card>
          <CardContent sx={{ padding: 2 }}>
            {props.campo.tipo === 1 && (
              <>
                <TextField
                  variant="filled"
                  inputMode="text"
                  {...inputProps}
                  onChange={(e) => {
                    handleValidate(e.target.value, validate);
                    inputProps.onChange(e);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <OpenInFull
                          sx={{
                            opacity: 0.5,
                          }}
                          onClick={() => setOpenDialog(true)}
                          style={{ cursor: "pointer" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />

                <Dialog
                  open={openDialog}
                  onClose={() => setOpenDialog(false)}
                  TransitionComponent={Transition}
                  maxWidth="xl"
                >
                  <Box sx={style}>
                    <Typography variant="h5">{props.campo.nome}</Typography>
                    <TextareaAutosize
                      {...inputProps}
                      style={styleTextArea}
                      onChange={(e) => {
                        handleValidate(e.target.value, validate);
                        inputProps.onChange(e);
                      }}
                    />
                    <Button color="success" variant="contained" onClick={() => setOpenDialog(false)}>
                      Ok
                    </Button>
                  </Box>
                </Dialog>
              </>
            )}

            {props.campo.tipo === 2 && <TextField variant="filled" inputMode="numeric" {...inputProps} />}

            {props.campo.tipo === 3 && <TextField variant="filled" inputMode="decimal" {...inputProps} />}

            {props.campo.tipo === 5 && props.campo.camposSelect.length < limitRadioGroup && (
              <FormControl required={inputProps.required} error={inputProps.error}>
                <Tooltip title={props.campo.nome} placement="right-end">
                  <FormLabel
                    sx={{
                      "&.Mui-focused": {
                        color: theme.palette.secondary.main,
                      },
                    }}
                  >
                    {props.campo.nome}
                  </FormLabel>
                </Tooltip>
                <RadioGroup value={inputProps.value} onChange={inputProps.onChange}>
                  {props.campo.camposSelect
                    .sort((a, b) => a.nome?.localeCompare(b.nome))
                    .map((option) => {
                      return (
                        <Tooltip key={option.nome} title={option.nome} placement="right-end">
                          <FormControlLabel
                            value={option.tblValorDb}
                            control={
                              <Radio
                                sx={{
                                  "&.Mui-checked": {
                                    color: theme.palette.secondary.main,
                                  },
                                }}
                              />
                            }
                            label={option.nome}
                          />
                        </Tooltip>
                      );
                    })}
                  <Tooltip key={`${props.campo.nome}_null`} title={"Não atribuído"} placement="right-end">
                    <FormControlLabel
                      value={"-1"}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: theme.palette.secondary.main,
                            },
                          }}
                        />
                      }
                      label={"Não atribuído"}
                    />
                  </Tooltip>
                </RadioGroup>
                <FormHelperText>{errors?.[inputId]?.message}</FormHelperText>
              </FormControl>
            )}

            {props.campo.tipo === 6 && (
              <FormControl>
                <Tooltip title={props.campo.nome} placement="right-end">
                  <FormLabel
                    sx={{
                      "&.Mui-focused": {
                        color: theme.palette.secondary.main,
                      },
                    }}
                  >
                    {props.campo.nome}
                  </FormLabel>
                </Tooltip>
                <RadioGroup value={inputProps.value} onChange={inputProps.onChange}>
                  <Tooltip key={props.campo.nome + `-sim`} title={`Sim`} placement="right-end">
                    <FormControlLabel
                      value={true}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: theme.palette.secondary.main,
                            },
                          }}
                        />
                      }
                      label={`Sim`}
                    />
                  </Tooltip>
                  <Tooltip key={props.campo.nome + `-nao`} title={`Não`} placement="right-end">
                    <FormControlLabel
                      value={false}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: theme.palette.secondary.main,
                            },
                          }}
                        />
                      }
                      label={`Não`}
                    />
                  </Tooltip>
                </RadioGroup>
              </FormControl>
            )}

            {props.campo.tipo === 7 && (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker {...inputProps} {...inputDateProps} />
              </LocalizationProvider>
            )}

            {props.campo.tipo === 8 && (
              <FormControl variant="filled" sx={{ width: "100%" }}>
                <InputLabel
                  sx={{
                    "&.Mui-focused": {
                      color: theme.palette.secondary.main,
                    },
                  }}
                >
                  {inputProps.label}
                </InputLabel>
                <Select
                  {...inputProps}
                  variant="filled"
                  onChange={(e) => inputProps.onChange(e as any)}
                  onClose={() => fetchBairrosOrLogradouros()}
                  MenuProps={{ autoFocus: false }}
                >
                  <ListSubheader>
                    <TextField
                      variant="standard"
                      autoFocus
                      inputMode="search"
                      fullWidth
                      onChange={(e) => setSearch(e.target.value || "")}
                      placeholder="Pesquisar..."
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>
                  {props.campo.camposSelect.length !== 0 ? (
                    props.campo.camposSelect
                      .sort((a, b) => a.nome?.localeCompare(b.nome))
                      .map((item, index) => {
                        return (
                          <MenuItem key={`${item.nome}_${index}`} value={item.id}>
                            {`${item.nome || item.id} (${item.id})`}
                          </MenuItem>
                        );
                      })
                  ) : (
                    <MenuItem key={`undefined`} value={undefined}>
                      Nenhum dado encontrado
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}

            {props.campo.camposSelect.length >= limitRadioGroup &&
              !searchableColumns.includes(props.campo.tblNomeColuna) && (
                <FormControl variant="filled" sx={{ width: "100%" }} >
                  <InputLabel
                    sx={{
                      "&.Mui-focused": {
                        color: theme.palette.secondary.main,
                      },
                    }}
                  >
                    {inputProps.label}
                  </InputLabel>
                  <Select
                    {...inputProps}
                    variant="filled"
                    onChange={(e) => inputProps.onChange(e as any)}
                    onClose={() => setSearch(undefined)}
                    MenuProps={{ autoFocus: false }}
                    placeholder="Pesquisar..."
                  >
                    <ListSubheader>
                      <TextField
                        variant="standard"
                        autoFocus
                        inputMode="search"
                        fullWidth
                        onChange={(e) => setSearch(e.target.value || "")}
                        placeholder="Pesquisar..."
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                    </ListSubheader>
                    {props.campo.camposSelect.length !== 0 ? (
                      props.campo.camposSelect
                        .filter((item) => normalizeWord(item.nome).includes(normalizeWord(search || "")))
                        .map((item, index) => {
                          return (
                            <MenuItem key={`${item.nome}_${index}`} value={item.tblValorDb}>
                              {`${item.nome || item.tblValorDb} (${item.tblValorDb})`}
                            </MenuItem>
                          );
                        })
                    ) : (
                      <MenuItem key={`undefined`} value={undefined}>
                        Nenhum dado encontrado
                      </MenuItem>
                    )}
                    <MenuItem key={`${props.campo.nome}_null`} value={"-1"}>
                      {`Não atribuido`}
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
          </CardContent>
        </Card>
      )}
    </>
  );
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
