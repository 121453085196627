import { Box, Skeleton, alpha, useTheme } from "@mui/material";
import { getCenter } from "ol/extent";
import GeoJSON from "ol/format/GeoJSON";
import { transform } from "ol/proj";
import { useContext, useEffect, useState } from "react";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import { Foto360 } from "../../../interfaces";
import { DashboardContext } from "../../../providers/Dashboard";
import { getPhotoSphere } from "../../../services/api";
import { getValue } from "../../../services/db";

export default function Viewer360() {
  const [photoSphere, SetPhotoSphere] = useState("");
  const [azimuth, SetAzimuth] = useState(0);
  const [coords, setCoords] = useState<number[]>();
  const [dataPhotoSphere, setDataPhotoSphere] = useState<Foto360>();

  const theme = useTheme();

  const { metadata, setMetadata } = useContext(DashboardContext);

  useEffect(() => {
    if (metadata.id_lote && !coords) {
      const feature = new GeoJSON().readFeature(getValue(metadata.id_lote!, "lote", "geom"), {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });

      //@ts-ignore
      setCoords(transform(getCenter(feature.getGeometry()?.getExtent()!), "EPSG:3857", "EPSG:4326"));
    }
  });

  useEffect(() => {
    if (!dataPhotoSphere && coords) {
      getPhotoSphere(coords).then((data) => {
        setDataPhotoSphere(data);
      });
    }
  }, [coords]);

  useEffect(() => {
    if (dataPhotoSphere && coords) {
      const photoCoords: Array<number> = [Number(dataPhotoSphere?.longitude), Number(dataPhotoSphere?.latitude)];
      const azimuthLote: number = calculateAzimuth(coords, photoCoords);

      SetAzimuth(azimuthLote - Number(dataPhotoSphere?.azimuth));
      SetPhotoSphere(dataPhotoSphere.link_foto);
    }
  }, [dataPhotoSphere]);

  if (photoSphere !== "") {
    return (
      <Box
        sx={{
          color: alpha(theme.palette.primary.main, 0.87),
        }}
      >
        {/* <Box sx={{
            position: 'absolute',
            right: '20px',
            bottom: '10px',
            display: 'flex',
            zIndex: '100000',
            color: 'white',
        }}>
          Frame: {dataPhotoSphere?.frame}
        </Box> */}
        <div className="container-photo-sphere">
          <ReactPhotoSphereViewer
            src={photoSphere}
            container={"container-photo-sphere"}
            defaultYaw={`${azimuth}deg`}
            caption={dataPhotoSphere?.frame}
            description={
              dataPhotoSphere &&
              Object.keys(dataPhotoSphere!)
                .map((e) => descriptionTemplate(e, (dataPhotoSphere as any)[e]))
                .join("")
            }
            defaultZoomLvl={1}
            height={"calc(100vh - 50px)"}
            width={"100%"}
            navbar={["autorotate", "zoom", "caption", "fullscreen", "description", "download"]}
          />
        </div>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          height: "calc(100vh - 50px)",
        }}
      >
        <Skeleton height={"100%"} />
      </Box>
    );
  }
}

function descriptionTemplate(name: string, value: any) {
  function formatValue(key: string, value: any) {
    if (key.includes("link")) {
      return `<a href="${value}" target="_blank">${key}</a>`;
    } else {
      return `<p>${value}</p>`;
    }
  }

  if (name === "geom") return "";

  return `<div className="description">
      <p><b>${name}</b></p>
      ${formatValue(name, value)}
    </div>`;
}

function keepItLessThan360(angle: number): number {
  if (angle < 0) {
    return angle + 360;
  }

  if (angle > 360) {
    return keepItLessThan360(angle - 360);
  } else {
    return angle;
  }
}

function calculateAzimuth(origin: Array<number>, destination: Array<number>): number {
  const deltaX = origin[0] - destination[0];
  const deltaY = origin[1] - destination[1];

  const rumo = Math.abs((Math.atan(deltaX / deltaY) * 180) / Math.PI);

  if (deltaX > 0 && deltaY > 0) {
    return keepItLessThan360(rumo);
  } else if (deltaX > 0 && deltaY < 0) {
    return keepItLessThan360(180 - rumo);
  } else if (deltaX < 0 && deltaY < 0) {
    return keepItLessThan360(rumo + 180);
  } else if (deltaX < 0 && deltaY > 0) {
    return keepItLessThan360(2 * 180 - rumo);
  } else {
    return 0;
  }
}
