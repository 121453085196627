const variablesByEnvironments: {
  [key: string]: {
    urlApiPlataforma: string;
    urlApiCadastro: string;
    urlApiGateway: string;
    ambiente: string;
  };
} = {
  topocartDev: {
    urlApiPlataforma: "https://staging.api.geo360.topocart.dev.br",
    urlApiCadastro: "https://cadastro.desenvolvimento.geo360.topocart.dev.br",
    urlApiGateway: "https://staging.gateway.geo360.topocart.dev.br",
    ambiente: "Ambiente de Desenvolvimento - Topocart",
  },
  topocart: {
    urlApiPlataforma: "https://api.geo360.topocart.dev.br",
    urlApiCadastro: "https://icad.geo360.topocart.dev.br",
    urlApiGateway: "https://gateway.geo360.topocart.dev.br",
    ambiente: "Topocart",
  },
  etopocartDev: {
    urlApiPlataforma: "https://plataforma.dev.etopocart.com",
    urlApiCadastro: "https://cadastro.dev.etopocart.com",
    urlApiGateway: "https://gateway.dev.etopocart.com",
    ambiente: "Ambiente de Desenvolvimento - eTopocart",
  },
  etopocart: {
    urlApiPlataforma: "https://plataforma.web.etopocart.com",
    urlApiCadastro: "https://cadastro.web.etopocart.com",
    urlApiGateway: "https://gateway.web.etopocart.com",
    ambiente: "eTopocart",
  },
  portoVelho: {
    urlApiPlataforma: "https://plataforma-geo360.portovelho.ro.gov.br",
    urlApiCadastro: "https://cadastro-geo360.portovelho.ro.gov.br",
    urlApiGateway: "https://gateway-geo360.portovelho.ro.gov.br",
    ambiente: "Porto Velho",
  },
  alexania: {
    urlApiPlataforma: "https://plataforma-geo360.alexania.go.gov.br",
    urlApiCadastro: "https://cadastro-geo360.alexania.go.gov.br",
    urlApiGateway: "https://gateway-geo360.alexania.go.gov.br",
    ambiente: "Alexania",
  },
  topoProd: {
    urlApiPlataforma: "https://plataforma.web.etopocart.com",
    urlApiCadastro: "https://cadastro.web.etopocart.com",
    urlApiGateway: "https://gateway.web.etopocart.com",
    ambiente: "eTopocart",
  },
  engeplus: {
    urlApiPlataforma: "https://plataforma.apps2.etopocart.com",
    urlApiCadastro: "https://cadastro.apps2.etopocart.com",
    urlApiGateway: "https://gateway.apps2.etopocart.com",
    ambiente: "eTopocart",
  },
  mesquita: {
    urlApiPlataforma: "https://plataforma.geo360.com.br",
    urlApiCadastro: "https://cadastro.geo360.com.br",
    urlApiGateway: "https://api-gateway.geo360.com.br",
    ambiente: "eTopocart",
  },
};

export const environments = variablesByEnvironments[process.env.REACT_APP_AMBIENTE || "topocartDev"];
