import { Box, Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { isCurrentColorModeDark } from "../../../providers/Theme";

export default function TabNavigation() {
  const sections = ["Lote", "Imobiliário", "Pessoa", "Edificações", "Mensagens", "Visitas & Controle"];

  const styles = {
    color: isCurrentColorModeDark() ? "#C7D0DD" : "#1C2025",
    flexWrap: "wrap",
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function scrollToSection(id: string) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    handleClose();
  }

  function handleMouseEnter(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <>
      <Button
        id="button"
        color="inherit"
        aria-controls={open ? "menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
      >
        Acesso rápido
      </Button>
      <Box sx={{ position: "absolute" }}>
        <Menu
          id="menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {sections.map((section, index) => (
            <MenuItem key={index} onClick={() => scrollToSection(section)} sx={styles}>
              {section}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
}
