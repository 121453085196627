import { Box, CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { createContext, useMemo, useState } from "react";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

/**
 * Verifica se o modo de cor atual é escuro.
 * @returns {boolean} Retorna true se o modo de cor atual for escuro, caso contrário, retorna false.
 */
export function isCurrentColorModeDark(): boolean {
  // Verificando a preferencia de cores do sistema
  const systemColorMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;

  const userColorMode = localStorage.getItem("mode");

  if (userColorMode) {
    return userColorMode === `dark`;
  } else {
    return systemColorMode;
  }
}

/**
 * Contexto para controle do modo de cor da aplicação.
 * @type {React.Context<{ toggleColorMode: () => void }>}}
 */
export const ColorModeContext = createContext({ toggleColorMode: () => {} });

/**
 * Componente de tema para controle do modo de cor da aplicação.
 * @param {Object} props - Propriedades do componente.
 * @param {React.ReactNode} props.children - Componentes filhos a serem envolvidos pelo tema.
 * @returns {JSX.Element} Retorna o componente de tema que controla o modo de cor da aplicação.
 */
export default function Theme({ children }: any) {
  const [mode, setMode] = useState<"light" | "dark">(isCurrentColorModeDark() ? "dark" : "light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          localStorage.setItem("mode", prevMode === "light" ? "dark" : "light");
          return prevMode === "light" ? "dark" : "light";
        });
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
          primary: {
            main: "#333840",
          },
          secondary: {
            main: "#009c66",
          },
          success: {
            main: "#009c66",
          },
        },
        typography: {
          fontFamily: "Poppins, sans-serif",
          fontSize: 12,
        },
      }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            backgroundColor: mode === "dark" ? "inherit" : "whitesmoke",
          }}
        >
          {children}
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
