import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  paddingTop: "2px",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  padding: theme.spacing(1, 1, 1, 1),
  paddingLeft: `calc(${theme.spacing(3)})`,
  transition: theme.transitions.create("width"),
  width: "0px",
}));

export default function SearchField(props: { value: string; setValue: React.Dispatch<React.SetStateAction<string>> }) {
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Pesquisar"
          inputProps={{ "aria-label": "search" }}
          value={props.value}
          className={props.value ? "search-active" : ""}
          sx={{
            width: props.value ? "200px" : "0px",
            "&:focus": {
              width: "200px",
              borderBottom: "1px solid primary.ligth",
            },
            "&:active": {
              width: "200px",
            },
            "&:hover": {
              width: "200px",
            },
          }}
          onChange={(e) => {
            props.setValue(e.target.value);
          }}
        />
      </Search>
    </Box>
  );
}
