import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  Typography,
  alpha,
  useTheme
} from "@mui/material";
import { MuiMarkdown, getOverrides } from "mui-markdown";
import { ReactNode, useContext, useEffect, useState } from "react";
import { IRelease } from "../../../interfaces";
import { ColorModeContext } from "../../../providers/Theme";
import { getGithubReleases } from "../../../services/api";

function formatBody(markdownText: string) {
  const linkRegex = /\[([^\]]*)\]\(([^)]*)\)/g;

  const textWithoutLinks = markdownText
    .replace(/# \[.*\].*\n/g, "")
    .replace(linkRegex, "$1")
    .replace("Bug Fixes", "Correções e Melhorias")
    .replace("Features", "Novas Funcionalidades")
    .replace("Reverts", "Reversões")
    .replace(/^.|\n./g, (match) => match.toUpperCase())
    .replace(/#/g, "");

  return textWithoutLinks;
}

export function DialogVersion(props: { children: ReactNode }) {
  const theme = useTheme();
  const color = useContext(ColorModeContext);

  const [openDialog, setOpenDialog] = useState(false);
  const [latestReleases, setLatestReleases] = useState<IRelease[] | null>(null);

  function handleOpenDialog() {
    setOpenDialog(true);
  };

  function handleCloseDialog(name: string | null = null) {
    setOpenDialog(false);
    localStorage.setItem("lastStoredVersion", name || "");
  };

  async function fetchData() {
    try {
      const releases = await getGithubReleases();
      setLatestReleases(releases);

      const currentVersion = releases[0]?.name;
      const lastStoredVersion = localStorage.getItem("lastStoredVersion");

      if (!lastStoredVersion || lastStoredVersion !== currentVersion) {
        handleOpenDialog();
      } else {
        handleCloseDialog(releases[0]?.name);
      }

    } catch (error: any) {
      console.error("Erro ao obter a última versão:", error.message || error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ButtonBase component="div">
        <Typography
          onClick={handleOpenDialog}
          variant="caption"
          fontStyle={{
            color: alpha(theme.palette.text.secondary, 0.4),
          }}
          sx={{
            cursor: "pointer",
          }}
        >
          {props.children}
        </Typography>
      </ButtonBase>

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={openDialog}
        onClose={() => handleCloseDialog(latestReleases && latestReleases[0]?.name)}
      >
        <DialogTitle
          sx={{
            fontSize: theme.typography.h5.fontSize,
            fontWeight: "bold",
            textAlign: "center",
            borderBottom: "1px solid #828282",
          }}
        >
          A Caracterização foi atualizada para a versão {latestReleases && latestReleases[0]?.name}. Veja as novidades.
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={() => handleCloseDialog(latestReleases && latestReleases[0]?.name)}
          component="button"
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          {latestReleases && (
            <>
              <Box
                key={latestReleases[0].id}
                sx={{
                  padding: theme.spacing(2),
                  borderRadius: theme.spacing(1),
                }}
              >
                <MuiMarkdown
                  overrides={{
                    ...getOverrides(),
                    p: {
                      component: Typography,
                      props: { variant: "h5", paragraph: true, style: { fontWeight: "bold", textAlign: "left" } },
                    },
                    ul: { component: List, props: { component: "ul", dense: true } },
                    ol: { component: List, props: { component: "ol", dense: true } },
                    li: {
                      component: Box,
                      props: {
                        component: "li",
                        dense: true,
                        style: {
                          listStyleType: "disc",
                          marginBottom: theme.spacing(1),
                          marginLeft: theme.spacing(2),
                        },
                      },
                    },
                  }}
                >
                  {formatBody(latestReleases[0].body)}
                </MuiMarkdown>
              </Box>

              <Typography
                variant="h6"
                gutterBottom
                textAlign="center"
                sx={{
                  textAlign: "center",
                  borderRadius: theme.spacing(1),
                  fontSize: theme.typography.h5.fontSize,
                  fontWeight: "bold",
                }}
              >
                Novidades das versões anteriores
              </Typography>

              {latestReleases
                .slice(1)
                .slice(-10)
                .map((release) => (
                  <Box
                    key={release.id}
                    sx={{
                      marginTop: theme.spacing(0.5),
                      padding: theme.spacing(2),
                      borderRadius: 1,
                    }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      textAlign="left"
                      sx={{ fontStyle: "italic", borderBottom: "2px solid #828282" }}
                    >
                      {release.name}
                    </Typography>

                    <MuiMarkdown
                      overrides={{
                        ...getOverrides(),
                        p: {
                          component: Typography,
                          props: { variant: "h5", paragraph: true, style: { fontWeight: "bold", textAlign: "left" } },
                        },
                        ul: {
                          component: List,
                          props: {
                            component: "ul",
                            dense: true,
                            style: {},
                          },
                        },
                        ol: { component: List, props: { component: "ol", dense: true } },
                        li: {
                          component: Box,
                          props: {
                            component: "li",
                            dense: true,
                            style: {
                              listStyleType: "disc",
                              marginBottom: theme.spacing(0.5),
                              marginLeft: theme.spacing(2),
                            },
                          },
                        },
                      }}
                    >
                      {formatBody(release.body)}
                    </MuiMarkdown>
                  </Box>
                ))}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
