import { Masonry } from "@mui/lab";
import { Box, MenuItem, TextField, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ProjetosCamadasFormularios, Tabelas } from "../../../interfaces";
import { DashboardContext } from "../../../providers/Dashboard";
import { DebounceProvider } from "../../../providers/Debounce";
import { getStructure } from "../../../services/db";
import { getFormPreference, saveFormPreference } from "../../../services/utils";
import Input from "../Input";

export default function Form(props: { formularios: ProjetosCamadasFormularios[]; tabela: Tabelas }) {
  const { metadata } = useContext(DashboardContext);
  const theme = useTheme();

  const [currentFormIndex, setCurrentFormIndex] = useState(0);

  let masonryColumns = metadata.drawer ? { xs: 1, sm: 1, md: 3, lg: 4, xl: 6 } : { xs: 1, sm: 2, md: 3, lg: 6, xl: 8 };

  function verifyIfCampoExistsInStructure(campo: string, tabela: Tabelas) {
    let structure = getStructure(tabela);

    if (!structure) return;

    if (structure.hasOwnProperty(campo)) {
      return true;
    } else {
      console.warn(
        `O campo ${campo} não existe na tabela ${tabela}, mas está definido no formulário ${props.formularios[currentFormIndex].nome}`,
      );
      return false;
    }
  }

  function handleFormChange(e: React.ChangeEvent<HTMLInputElement>) {
    setCurrentFormIndex(Number(e.target.value));
    saveFormPreference(props.tabela, e.target.value);
  }

  useEffect(() => {
    const form = getFormPreference(props.tabela);
    if (form) {
      setCurrentFormIndex(Number(form));
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          paddingTop: "1rem",
        }}
      >
        <TextField
          fullWidth
          select
          label="Selecione um formulário"
          defaultValue={0}
          onChange={handleFormChange}
          InputLabelProps={{
            sx: {
              "&.Mui-focused": {
                color: theme.palette.secondary.main,
              },
            },
          }}
        >
          {props.formularios.map((formulario, index) => (
            <MenuItem key={formulario.nome} value={index}>
              {formulario.nome}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box
        sx={{
          paddingTop: "1rem",
        }}
      >
        <Masonry columns={masonryColumns} spacing={1}>
          {props.formularios[currentFormIndex].projetosCamadasFormulariosCampos
            .filter((campo) => campo.ativo)
            .filter((campo) => verifyIfCampoExistsInStructure(campo.tblNomeColuna, props.tabela))
            .sort((a, b) => a.ordem - b.ordem)
            .map((campo, index) => {

              if (campo.tblNomeColuna === "id_bairro" || campo.tblNomeColuna === "id_logradouro") {
                campo.tipo = 8;
                campo.tipoCampo = "8";
              }
              
              return (
                <DebounceProvider key={index}>
                  <Input
                    key={`${metadata[`id_${props.tabela}`]!}@${props.tabela}@${campo.tblNomeColuna}`}
                    tabela={props.tabela}
                    campo={campo}
                  />
                </DebounceProvider>
              );
            })}
        </Masonry>
      </Box>
    </>
  );
}
