import { Masonry } from "@mui/lab";
import { Box, Skeleton } from "@mui/material";
import { useContext } from "react";
import { DashboardContext } from "../../../providers/Dashboard";

export default function FormSkeleton() {
  const { metadata } = useContext(DashboardContext);

  let masonryColumns = metadata.drawer ? { xs: 1, sm: 1, md: 3, lg: 4, xl: 6 } : { xs: 1, sm: 2, md: 3, lg: 6, xl: 8 };

  let skeletons = [1, -2, 3, -4, -2, 1, -3, 4, 3, 6, -1];

  return (
    <>
      <Box
        sx={{
          paddingTop: "1rem",
        }}
      >
        <Masonry columns={masonryColumns} spacing={1}>
          {skeletons.map((skeleton, index) => (
            <Skeleton key={index} variant="rounded" width={"210px"} height={60 + skeleton * 10} />
          ))}
        </Masonry>
      </Box>
    </>
  );
}
