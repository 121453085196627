import { createContext, useEffect, useState } from "react";
import { IDashboardContext, IErrors, IMetadata } from "../../interfaces";

/**
 * Contexto do Dashboard para gerenciamento de metadados e estado do painel de controle.
 * @type {React.Context<IDashboardContext>}
 */
export const DashboardContext = createContext<IDashboardContext>({
  metadata: {
    id_lote: undefined,
    id_imobiliario: undefined,
    id_pessoa: undefined,
    id_imobiliario_pessoa: undefined,
    id_edificacao: undefined,
    id_imobiliario_edificacao: undefined,
    id_midia: undefined,
    id_visitas: undefined,
    id_mensagens: undefined,
    formularios: undefined,
    drawer: true,
    permissoes: undefined
  },
  setMetadata: () => {},
  errors: {},
  setErrors: () => {},
  setShowDisabledImobiliario: () => {},
  showDisabledImobiliario: false,
  reloadFields: false,
  setReloadFields: () => {},
});

/**
 * Provedor do Dashboard responsável por gerenciar metadados associados ao painel de controle.
 * @param {Object} props - Propriedades do componente.
 * @param {React.ReactNode} props.children - Componentes filhos a serem envolvidos pelo provedor do Dashboard.
 * @returns {JSX.Element} Retorna o provedor do Dashboard que gerencia os metadados do painel de controle.
 */
export function DashboardProvider({ children }: any) {
  const [metadata, setMetadata] = useState<IMetadata>({
    id_lote: undefined,
    id_imobiliario: undefined,
    id_pessoa: undefined,
    id_imobiliario_pessoa: undefined,
    id_edificacao: undefined,
    id_imobiliario_edificacao: undefined,
    id_midia: undefined,
    id_visitas: undefined,
    id_mensagens: undefined,
    formularios: undefined,
    drawer: true,
    permissoes: undefined
  });

  const [errors, setErrors] = useState<IErrors>({});
  const [showDisabledImobiliario, setShowDisabledImobiliario] = useState<boolean>(false);
  const [reloadFields, setReloadFields] = useState<boolean>(false);

  useEffect(() => {
    console.log("metadata has changed -> ", metadata);
  }, [metadata]);

  useEffect(() => {
    console.log("errors has changed -> ", errors);
  }, [errors]);

  return (
    <DashboardContext.Provider
      value={{
        metadata,
        setMetadata,
        errors,
        setErrors,
        showDisabledImobiliario,
        setShowDisabledImobiliario,
        reloadFields,
        setReloadFields,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}
